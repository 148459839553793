import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27')
];

export const server_loads = [];

export const dictionary = {
		"/(fullwidth)": [20,[4]],
		"/(fullwidth)/about": [21,[4]],
		"/(app)/explore": [6,[2]],
		"/(app)/explore/[slug]": [7,[2]],
		"/(app)/explore/[slug]/[block]": [8,[2]],
		"/(fullwidth)/guide": [22,[4]],
		"/(fullwidth)/legal/licenses": [23,[4]],
		"/(fullwidth)/legal/privacy": [24,[4]],
		"/(fullwidth)/legal/terms": [25,[4]],
		"/(app)/profile/[slug]": [9,[2]],
		"/(standalone)/purchase/[slug]": [27,[5]],
		"/(app)/store": [10,[3]],
		"/(fullwidth)/travel-restrictions": [26,[4]],
		"/(app)/trips": [11,[2]],
		"/(app)/trips/[slug]": [12,[2]],
		"/(app)/trips/[slug]/highlights": [14,[2]],
		"/(app)/trips/[slug]/listing": [15,[2]],
		"/(app)/trips/[slug]/preview": [16,[2]],
		"/(app)/trips/[slug]/preview/highlights": [18,[2]],
		"/(app)/trips/[slug]/preview/[block]": [17,[2]],
		"/(app)/trips/[slug]/transfer": [19,[2]],
		"/(app)/trips/[slug]/[block]": [13,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';